import React, {useState} from 'react'
import { Grid,Box,Stack, TextField,Button, Typography, Container, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {  experimentalStyled as styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { postManualRecon,postBulkRecon} from '../redux/slices/sliceActions'
import SnackBar from '../components/snackBar';




const  Recons = () => {
    const dispatch = useDispatch()
    const { loading ,user} = useSelector(state => state.usersslice);
    const token = user?.accessToken
    const [show, setshow] = useState(false)
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    

    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setFileName(selectedFile ? selectedFile.name : '');
    };

    const [formData, setFormData] = useState({
      TransID: "",
      TransTime: "",
      TransAmount: "",
      BusinessShortCode: "",
      BillRefNumber: "",
      MSISDN: "",
      FirstName: ""

      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

      const handleFileSubmit = (e) => {
        e.preventDefault();
        if (file) {
          
          dispatch(postBulkRecon({ token, file }))
            .unwrap()
            .then((res) => {
              
              if (res.status === 'success') {
                setshow({ open: true, text: res.message, mood: 'success' });
                setFile(null);
                setFileName('');
              } else {
                setshow({ open: true, text: 'Failed, please try again', mood: 'error' });
              }
            });
        } else {
          setshow({ open: true, text: 'Please select a file to upload', mood: 'warning' });
        }
      };

    
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(postManualRecon({token,formData}))
        .unwrap()
        .then((res)=>{
          console.log(res)
          if(res.status === 'success'){
            Swal.fire({
              title:'Request succesful',
              text: res.message,
              icon:'success',
              confirmButtonColor: "#3085d6",
          })
            // setshow({open:true, text: 'Upload was successful', mood: 'success'})
            setFormData({
              TransID: "",
              TransTime: "",
              TransAmount: "",
              BusinessShortCode: "",
              BillRefNumber: "",
              MSISDN: "",
              FirstName: ""

            });
          }else {
            Swal.fire(
              'Failed',
              'Transaction Reposting Failed',
              'error'
            )
            // setshow({open:true, text: 'Transaction Reposting Failed', mood: 'error'})
          }
        })
        .catch((error)=>{
          console.log(error)
          Swal.fire(
            'Failed',
            'Transaction Reposting Failed',
            'error'
          )
        })
      };

      
  return (
    <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
           Reconciliation
          </Typography>
          <Button
            variant="contained"
            href="/recons.xlsx"
            download="recons.xlsx"
            startIcon={<CloudDownloadIcon  />}
            >
            Download sample
            
            </Button>

            <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            >
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleFileChange}  />
            
            </Button>
            {fileName && (
              <>
            <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
              Selected file: {fileName}
            </Typography>
            <Button onClick={handleFileSubmit} variant="contained" color="primary">
            Submit File
          </Button>
          </>
          )}
        </Stack>
        
      

    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 2, alignContent: 'center', width: '25ch', marginBottom:4 },
      }}
      noValidate
      autoComplete="off"
    >

        <TextField
            fullWidth
            label="Transaction Id"
            name="TransID"
            value={formData.TransID}
            onChange={handleChange}
            margin="normal"
          />
        
          <TextField
            fullWidth
           
            name="TransTime"
            type='datetime-local'
            format="MM-DD-YYYY"
            value={formData.TransTime}
            onChange={handleChange}
            margin="normal"
          /> 

          <TextField
            fullWidth
            label="Transaction Amount"
            name="TransAmount"
            value={formData.TransAmount}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Business ShortCode"
            name="BusinessShortCode"
            value={formData.BusinessShortCode}
            onChange={handleChange}
            fullWidth
            margin="normal"
          
            
          /> 
          {/* <FormControl fullWidth sx={{  borderRadius: 1 }}>
            <InputLabel >Payment Method</InputLabel>
            <Select
              label="Payment Method"
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleChange}
            >
              <MenuItem value="MPESA">MPESA</MenuItem>
              <MenuItem value="CASH">CASH</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            fullWidth
            label="BillRefNumber"
            name="BillRefNumber"
            value={formData.BillRefNumber}
            onChange={handleChange}
            margin="normal"
          />
          
          <TextField
            fullWidth
            label="Phone number"
            name="MSISDN"
            value={formData.MSISDN}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Payer Name"
            variant="outlined"
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
            fullWidth
             margin="normal"
          
            sx={{ borderRadius: 1 }}
          />
          <Box  mt={2}>
            <Button onClick={handleSubmit} variant="contained" color="primary" >
              Submit
            </Button>
          </Box>

         
      
    
    </Box>

   
   
   <SnackBar value={show.open} text={show.text} mood={show.mood}/>
  </Container>

   
  )
}

export default Recons