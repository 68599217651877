import { createSlice } from "@reduxjs/toolkit";
import   * as actions  from "./sliceActions"

const initialState = {
    loading: false,
    user: JSON.parse(localStorage.getItem("user")) || {},
    error: "",
    message: "",
    adminview: {},
    merchants: {},
    services : {},
    merchantservices: {},
    merchant: {},
    merchantaccounts: {},
    transactions: {},
    failedtransactions: {},
    issuetransactions: {},
    transaction: {},
    balances: {},
    downloadtrans:{}

}

const usersSlice = createSlice({
    name: "customer",
    initialState,
    reducers : {
        clearUser : (state)=> {
            state.user = {}
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(actions.loginUser.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.loginUser.fulfilled, (state, action)=>{
            state.loading = false
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(action.payload))
            state.message = "login successful"
            state.error = ""
        })
        builder.addCase(actions.loginUser.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })
        builder.addCase(actions.adminView.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.adminView.fulfilled, (state, action)=>{
            state.loading = false
            state.adminview = action.payload
            state.message = "transactions fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.adminView.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.fetchMerchants.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchMerchants.fulfilled, (state, action)=>{
            state.loading = false
            state.merchants = action.payload
            state.message = "merchants fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchMerchants.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })
        builder.addCase(actions.fetchMerchant.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchMerchant.fulfilled, (state, action)=>{
            state.loading = false
            state.merchant = action.payload
            state.message = "merchant fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchMerchant.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.createMerchant.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.createMerchant.fulfilled, (state, action)=>{
            state.loading = false
            console.log(action.payload)
            state.message = "merchant created successfully"
            state.error = ""
        })
        builder.addCase(actions.createMerchant.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.updateMerchant.pending, (state)=>{
            state.loading = true
        }) 

        builder.addCase(actions.updateMerchant.fulfilled ,(state, {payload})=>{
                state.loading = false
                console.log(payload)
                state.message = "merchant updated successfully"
        }) 

        builder.addCase(actions.updateMerchant.rejected ,(state, action)=>{
                state.loading = false
                state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
                state.message = action.error.message
        })

        builder.addCase(actions.logoutUser.pending, (state)=>{
            state.loading = true
        }) 

        builder.addCase(actions.logoutUser.fulfilled ,(state, {payload})=>{
                state.loading = false
                state.user = null
                console.log(payload)
                localStorage.removeItem("user")
                state.message = "logged out successfully"
        }) 

        builder.addCase(actions.logoutUser.rejected ,(state, action)=>{
                state.loading = false
                state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
                state.message = action.error.message
        })

        builder.addCase(actions.fetchServices.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchServices.fulfilled, (state, action)=>{
            state.loading = false
            state.services= action.payload
            state.message = "services fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchServices.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })
        builder.addCase(actions.fetchMerchantServices.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchMerchantServices.fulfilled, (state, action)=>{
            state.loading = false
            state.merchantservices= action.payload
            state.message = "Merchant services fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchMerchantServices.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.createServices.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.createServices.fulfilled, (state, action)=>{
            state.loading = false
            console.log(action.payload)
            state.message = "service created successfully"
            state.error = ""
        })
        builder.addCase(actions.createServices.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.fetchMerchantAccounts.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchMerchantAccounts.fulfilled, (state, action)=>{
            state.loading = false
            state.merchantaccounts = action.payload
            state.message = "merchants accounts fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchMerchantAccounts.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.fetchTransactions.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchTransactions.fulfilled, (state, action)=>{
            state.loading = false
            state.transactions = action.payload
            state.message = "transactions fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchTransactions.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.fetchTransaction.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchTransaction.fulfilled, (state, action)=>{
            state.loading = false
            state.transaction = action.payload
            state.message = "transactions fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchTransaction.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.fetchBalances.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.fetchBalances.fulfilled, (state, action)=>{
            state.loading = false
            state.balances = action.payload
            state.message = "balances fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.fetchBalances.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })
        builder.addCase(actions.failedTransactions.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.failedTransactions.fulfilled, (state, action)=>{
            state.loading = false
            state.failedtransactions = action.payload
            state.message = "failed transactions fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.failedTransactions.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })
        builder.addCase(actions.issueRTransactions.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.issueRTransactions.fulfilled, (state, action)=>{
            state.loading = false
            state.issuetransactions = action.payload
            state.message = "failed transactions fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.issueRTransactions.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })

        builder.addCase(actions.downloadTransactions.pending, (state)=> {
            state.loading = true;
        })
        builder.addCase(actions.downloadTransactions.fulfilled, (state, action)=>{
            state.loading = false
            state.downloadtrans = action.payload
            state.message = "transaction to download fetched successfully"
            state.error = ""
        })
        builder.addCase(actions.downloadTransactions.rejected, (state, action)=>{
            state.loading=false;
            state.error = action.error.message.split(" ")[action.error.message.split(" ").length-1]
            state.message = action.error.message
        })




    }
})

export const { clearUser } = usersSlice.actions

export default usersSlice.reducer;
