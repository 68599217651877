import React, { useState,  useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme,alpha, experimentalStyled as styled } from '@mui/material/styles';
import { Grid, Container, Typography,Stack,OutlinedInput,InputAdornment, TextField,IconButton,SearchIcon,Icon } from '@mui/material';
import Card from '@mui/material/Card';
import Iconify from '../components/iconify/Iconify';

import { adminView, fetchBalances, fetchTransactions } from '../redux/slices/sliceActions';
// components
import ExportButton from '../components/ExportButton';
import ExportToExcelButton from '../components/ExportToExcel';
import { ProductFilterSidebar } from '../sections/@dashboard/products';
import TransactionsListing from '../components/transactionsListing';
// sections


// ----------------------------------------------------------------------


export default function DashboardAppPage({color = 'primary', setPage}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { adminview,balances,transactions, user, loading, error} = useSelector(state => state.usersslice);
  const trans = adminview?.transactions
  const metadata = adminview?.metadata
  const stats = adminview?.statistics
  const token = user?.access_token
  
  const [limit, setlimit] = useState(10)
  const [page, setpage] = useState(1)

  const [filterName, setFilterName] = useState('');
  const [filterAccountName, setAccountFilterName] = useState('');
  const [applyFilters, setApplyFilters] = useState([])

  const [filterDetails, setFilterDetails] = useState({
    reference:"",
    filterAccountName: "",
    serviceStatus:"",
    filterService:"",
    transStatus:"",
    dateFrom: "",
    dateTo: "",
    paybill: "",
    id: "",
    amount: ""
  })

  const clearFilter = () => {
    setApplyFilters('');
    setFilterDetails({
    reference:"",
    filterAccountName: "",
    serviceStatus:"",
    filterService:"",
    transStatus:"",
    dateFrom: "",
    dateTo: "",
    id: "",
    amount: ""
    })
    
  }

  const handleValueChange = (e) => {
    setFilterDetails({ ...filterDetails , [e.target.name]:e.target.value})
  }

  const handleApplyFilters = () => {
    setApplyFilters(filterDetails)
  }
  const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
      setOpenFilter(true);
    };
  
    const handleCloseFilter = () => {
      setOpenFilter(false);
    };

  const handleFilterByName = (event) => {
    // setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterByAccount = (event) => {
    setAccountFilterName(event.target.value);
  }


 
 

  useEffect(() => {
    
    dispatch(adminView({token, page, applyFilters}));
    dispatch(fetchBalances())
    
  }, [dispatch, user, page, applyFilters ]);

  useEffect(() => {
    if(loading === false  && !user?.access_token || error === "403"){
      navigate('/login')
    }
    
  }, [loading,user, navigate]);
  
  return (
    <>
      <Helmet>
        <title> Dashboard | Kwetu Kash </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Hi, {user?.username}
        </Typography>
       

        <Grid container spacing={3} >

          <Grid item xs={12} sm={6} md={3}>
          <Card 
           sx={{
            boxShadow: 0,
            color: (theme) => theme.palette[color].darker,
            bgcolor: (theme) => theme.palette[color].lighter,
            
          }} >
              <Stack spacing={2} sx={{ p: 2 }}>
                  <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                  {stats?.[1].card_name}
                  </Typography>
              
                <Stack  justifyContent="space-between">
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                    Total {stats?.[1].total_count}
                  </Typography>
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                   Value {stats?.[1].total_value}
                   
                  </Typography>
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                  Success {stats?.[1].successful}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Card 
          sx={{
            boxShadow: 0,
            // textAlign: 'center',
            color: (theme) => theme.palette[color].darker,
            bgcolor: (theme) => theme.palette[color].lighter,
            
          }} >
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                {stats?.[2].card_name}
                </Typography>

                <Stack  justifyContent="space-between">
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                  Total {stats?.[2].total_count}
                  </Typography>
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                   Active {stats?.[2].total_active}
                  </Typography>
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                   Value {stats?.[2].total_value}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Card
           sx={{
            boxShadow: 0,
            color: (theme) => theme.palette[color].darker,
            bgcolor: (theme) => theme.palette[color].lighter,
            
          }} 
          >
              <Stack spacing={4.5} sx={{ p: 2 }}>
                  <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                   {stats?.[0]?.card_name}
                  </Typography>
              
                <Stack  justifyContent="space-between">
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                   Total {stats?.[0]?.total_count}
                  </Typography>
                  <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                   Active {stats?.[0]?.total_active}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Card
           sx={{
            boxShadow: 0,
            color: (theme) => theme.palette[color].darker,
            bgcolor: (theme) => theme.palette[color].lighter,
            
          }} 
          >
              <Stack spacing={4.5} sx={{ p: 2 }}>
                  <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                   {"BALANCES"}
                  </Typography>
              
                <Stack  justifyContent="space-between">
                  {balances.length > 0 ? (
                    <>
                    {balances?.map((balance, index)=>(
                   <>
                    <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                    {balance?.service_provider} :  {balance.running_balance}
                   </Typography>
                  
                   </>
                  ))}
                    </>
                  ):null}
                  
                  
                </Stack>
              </Stack>
            </Card>
          </Grid>
            
        </Grid>

        <Card sx={{ p:2, mt:3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <ProductFilterSidebar 
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            filterDetails={filterDetails}
            handleValueChange={handleValueChange}
            handleApplyFilters={handleApplyFilters}
            clear={clearFilter}
            // paybillList={paybillList}
            />
           
          {/* <ExportButton transactions={trans} /> */}
          <ExportToExcelButton token={token} applyFilters={applyFilters} />
        </Stack>
            
          
           <TransactionsListing transactions={trans} metadata={metadata} limit={limit} setlimit={setlimit} page = {page} setPage={setpage} />
        </Card>
       
      </Container>
    </>
  );
}
