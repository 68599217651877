import React from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  FormControl,InputLabel, MenuItem,Select
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ShopFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  handleApplyFilters: PropTypes.func,
  handleValueChange: PropTypes.func,
};

export default function ShopFilterSidebar({ openFilter, onOpenFilter, onCloseFilter,filterDetails, handleValueChange,handleApplyFilters,clear,paybillList }) {
 

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
             
              <TextField
                size='small'
                label="Account number"
                name='filterAccountName'
                value={filterDetails.filterAccountName}
                onChange={handleValueChange}
                sx={{marginLeft: '10px',maxWidth: 180}}
                
              />
              
            </div>

            <div>
              
              <TextField
                size='small'
                label="Reference"
                name='reference'
                value={filterDetails.reference}
                onChange={handleValueChange}
                sx={{marginLeft: '10px',maxWidth: 180}}
                
              />
              
            </div>

            <div>
              
              <TextField
                size='small'
                label="Transaction Id"
                name='id'
                value={filterDetails.id}
                onChange={handleValueChange}
                sx={{marginLeft: '10px',maxWidth: 180}}
                
              />
              
            </div>
            <div>
              
              <TextField
                size='small'
                label="Amount"
                name='amount'
                value={filterDetails.amount}
                onChange={handleValueChange}
                sx={{marginLeft: '10px',maxWidth: 180}}
                
              />
              
            </div>

            {/* <div>
              
              <FormControl sx={{ marginLeft: '10px', minWidth: 180 }} size="small">
                  <InputLabel id="demo-select-small-label">Service</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name='filterService'
                    value={filterDetails.filterService}
                    label="Service"
                    onChange={handleValueChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"airtel"}>Airtel</MenuItem>
                    <MenuItem value={"safaricom"}>Safaricom</MenuItem>
                    <MenuItem value={"telkom"}>Telkom</MenuItem>
                  </Select>
                </FormControl>
            </div> */}

            {/* <div>
              
              <FormControl sx={{ marginLeft: '10px', minWidth: 180 }} size="small">
                  <InputLabel id="demo-select-small-label">Service status</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name='serviceStatus'
                    value={filterDetails.serviceStatus}
                    label="Service status"
                    onChange={handleValueChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"SUCCESSFUL"}>Succesful</MenuItem>
                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                    <MenuItem value={"FAILED"}>Failed</MenuItem>
                    <MenuItem value={"JAMMED"}>Jammed</MenuItem>
                    <MenuItem value={"REVERSED"}>Reversed</MenuItem>
                    <MenuItem value={"REFUNDED"}>Refunded</MenuItem>
                  </Select>
                </FormControl>
            </div> */}

            {/* <div>
             
              <FormControl sx={{ marginLeft: '10px', minWidth: 180 }} size="small">
                  <InputLabel id="demo-select-small-label">Transaction status</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name='transStatus'
                    value={filterDetails.transStatus}
                    label="Transaction status"
                    onChange={handleValueChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={101}>Succesful</MenuItem>
                    <MenuItem value={100}>Pending</MenuItem>
                    <MenuItem value={103}>Failed</MenuItem>
                    <MenuItem value={102}>Issue resolution</MenuItem>
                    <MenuItem value={107}>Reversed</MenuItem>
                    <MenuItem value={108}>Refunded</MenuItem>
                  </Select>
                </FormControl>
            </div> */}

            {/* <div>
            <FormControl  sx={{ marginLeft: '10px' ,minWidth: 180 }}size="small">
              <InputLabel>Paybill</InputLabel>
              <Select
                  label="Paybill"
                  name="paybill"
                  value={filterDetails.paybill}
                  onChange={handleValueChange}
              >
                  {paybillList?.map((paybil) => (
                      <MenuItem key={paybil.shortCode} value={paybil.shortCode}>{paybil.shortCode}</MenuItem>
                  ))}
              </Select>
                </FormControl>
             
              
            </div> */}

            <div>
              <Typography variant="subtitle1" marginLeft={'10px'} gutterBottom>
                From
              </Typography>
              <TextField
                size='small'
                name='dateFrom'
                type='datetime-local'
                value={filterDetails.dateFrom}
                onChange={handleValueChange}
                sx={{marginLeft: '10px',maxWidth: 180}}
                
              />
              
            </div>

            <div>
              <Typography variant="subtitle1" marginLeft={'10px'} gutterBottom>
                To
              </Typography>
              <TextField
                size='small'
                name='dateTo'
                type='datetime-local'
                value={filterDetails.dateTo}
                onChange={handleValueChange}
                sx={{marginLeft: '10px',maxWidth: 180}}
                
              />
              
            </div>

            
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 2 }}>
          <Button
          onClick={handleApplyFilters}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-filter-list" />}
          >
            Apply filters
          </Button>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
          onClick={clear}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear Filters
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
