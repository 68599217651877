import React, { useState } from 'react';
import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';

export const baseUrl = process.env.REACT_APP_ENDPOINT;

const ExportToExcelButton = ({token, applyFilters}) => {
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    try {
      setLoading(true);
      const filter = applyFilters.reference || '';
      const account = applyFilters.filterAccountName || '';
      const service = applyFilters.filterService || '';
      const pstatus = applyFilters.transStatus || '';
      const sstatus = applyFilters.serviceStatus || '';
      const dateFrom = applyFilters.dateFrom || '';
      const dateTo = applyFilters.dateTo || '';
      const paybill = applyFilters.paybill || '';
      const id = applyFilters.id || '';

      const response = await axios.get(`${baseUrl}/v1/transactions/download?from=${dateFrom}&to=${dateTo}`, {
        responseType: 'blob', 
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);

     
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'transactions.xlsx'); 
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
    } catch (error) {
      console.error('Error exporting transactions:', error);
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      startIcon={<CloudDownloadIcon />}
      onClick={handleExport}
      disabled={loading}
    >
      {loading ? 'Exporting...' : 'Export to Excel'}
    </Button>
  );
};

export default ExportToExcelButton;
